import "./PodcastCarousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useEffect, useState } from "react";

import { BiCaretRightCircle } from "react-icons/bi";
import Slider from "react-slick";
import type SpotifyEpisode from "../../types/Spotify";
import type { SpotifyImage } from "../../types/Spotify";

type PodCastCarouselProps = {
  episodes: SpotifyEpisode[];
  toShow: number;
};

type PodcastItemProps = {
  episode: SpotifyEpisode;
};

function PodcastItem({ episode }: PodcastItemProps) {
  const { external_urls, name, images } = episode;
  const [width, setWidth] = useState(window.innerWidth);
  const [imageBestSize, setImageBestSize] = useState<number>(600);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    if (width >= 1024) {
      setImageBestSize(600);
    } else if (width >= 600) {
      setImageBestSize(300);
    } else {
      setImageBestSize(64);
    }
  }, [width]);

  const getImageSourceSet = (images: SpotifyImage[]): string => {
    let result: string = "";
    images.forEach((img: SpotifyImage) => {
      if (result.length) result = result + ", ";

      result = result + img.url + " " + img.width + "w";
    });
    return result;
  };
  const getAppropriateImage = (size: number): string => {
    return images.find((img) => img.width === size)?.url || images[0].url;
  };

  const image = getAppropriateImage(imageBestSize);

  const getSpotifyEmbedUrl = (externalUrl: string): string => {
    const episodeId = externalUrl.split("/episode/")[1]; // Extract the episode ID
    return `https://open.spotify.com/episode/${episodeId}`;
  };

  return (
    <div className="podcast-item">
      <div className="podcast-item-container">
        <div className="podcast-post">
          <div className="podcast-post-thumbnail">
            <a href={external_urls.spotify}>
              <img
                decoding="async"
                src={image}
                className="podcast-post-image"
                alt=""
                srcSet={getImageSourceSet(images)}
                sizes="(max-width: 400px) 64px, (max-width: 800px) 300px, 640px"
              />
            </a>
            <div className="podcast-post-actions">
              <a
                className="vice-post-actions-icon"
                data-playtrack={getSpotifyEmbedUrl(external_urls.spotify)}
                data-title={name}
                data-author=""
                data-cover={image}
                data-releasepage={external_urls.spotify}
                href={getSpotifyEmbedUrl(external_urls.spotify)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BiCaretRightCircle
                  style={{
                    position: "absolute",
                    top: "15%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  size={70}
                />
              </a>
            </div>
          </div>
          <div className="podcast-post-content">
            <div className="podcast-post-details"></div>
            <h3 className="podcast-post-caption">
              <a href={external_urls.spotify}>{name}</a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function PodCastCarousel({
  episodes,
  toShow,
}: PodCastCarouselProps) {
  const options = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: toShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    centerMode: true,
    centerPadding: "0px",
    arrows: true,
    gap: 15,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024, // Screen width up to 1024px
        settings: {
          slidesToShow: 3, // Show 3 items
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Screen width up to 768px (tablet)
        settings: {
          slidesToShow: 2, // Show 2 items on tablet
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Screen width up to 600px (horizontal mobile)
        settings: {
          slidesToShow: 2, // Show 2 items on horizontal mobile
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Screen width up to 480px (mobile)
        settings: {
          slidesToShow: 1, // Show 1 item on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider className="pod-carousel" {...options}>
      {episodes.map((episode: SpotifyEpisode) => {
        return <PodcastItem key={episode.id} episode={episode} />;
      })}
    </Slider>
  );
}
