import type SpotifyEpisode from "../../types/Spotify";

const {
  PINK_SPOTIFY_CLIENT_ID,
  PINK_SPOTIFY_CLIENT_SECRET,
  PINK_SPOTIFY_API_ENDPOINT,
  PINK_SPOTIFY_API_VERSION,
  PINK_SPOTIFY_API_TOKEN_ENDPOINT,
  PINK_SPOTIFY_SHOW_ID,
  PINK_SPOTIFY_CAROUSEL_EPISODES_MAX,
  PINK_SPOTIFY_CAROUSEL_EPISODES_VISIBLE,
} = import.meta.env as Record<string, string>;

export const spotifyConfig = {
  showId: PINK_SPOTIFY_SHOW_ID,
  showEpisodesPerRow: PINK_SPOTIFY_CAROUSEL_EPISODES_VISIBLE,
  showEpisodesMax: PINK_SPOTIFY_CAROUSEL_EPISODES_MAX,
};

interface SpotifyTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

interface SpotifyEpisodesResponse {
  items: SpotifyEpisode[];
}

async function GetAccessTokenAsync(): Promise<string> {
  const controller = new AbortController();

  return fetch(PINK_SPOTIFY_API_TOKEN_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${btoa(
        `${PINK_SPOTIFY_CLIENT_ID}:${PINK_SPOTIFY_CLIENT_SECRET}`
      )}`,
    },
    body: new URLSearchParams({ grant_type: "client_credentials" }).toString(),
    signal: controller.signal,
  })
    .then(async (response) => {
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to get access token from Spotify. Status: ${response.status}. Response: ${errorText}`
        );
      }

      return (await response.json()) as SpotifyTokenResponse;
    })
    .then((data) => data.access_token)
    .catch((error: unknown) => {
      if (controller.signal.aborted) {
        throw new Error("Failed to acquire access token from spotify");
      }

      if (error instanceof Error) {
        throw new Error(
          "Failed to get access token from Spotify: " + error.message
        );
      }

      throw new Error("Failed to get access token from Spotify: Unknown error");
    });
}

export default async function GetLatestEpisodesAsync(
  showId: string,
  cnt: number
): Promise<SpotifyEpisode[]> {
  const accessToken = await GetAccessTokenAsync();

  const controller = new AbortController();
  return fetch(
    `${PINK_SPOTIFY_API_ENDPOINT}/${PINK_SPOTIFY_API_VERSION}/shows/${showId}/episodes?limit=${cnt}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: controller.signal,
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch episodes from Spotify");
      }

      return response.json();
    })
    .then((data: SpotifyEpisodesResponse) => {
      if (!Array.isArray(data.items)) {
        throw new Error(
          "Invalid fetch episodes from Spotify response structure"
        );
      }

      return data.items;
    })
    .catch((error: unknown) => {
      if (controller.signal.aborted) {
        throw new Error("Failed to acquire episodes from spotify");
      }

      if (error instanceof Error) {
        throw new Error(
          "Failed to acquire episodes from Spotify: " + error.message
        );
      }

      throw new Error("Failed to acquire episodes from Spotify: Unknown error");
    });
}
