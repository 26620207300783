import "./main.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App.tsx";
import ReactDOM from "react-dom/client";

// get the root element
const container = document.getElementById("root");
if (!container) throw new Error("Failed to find react root element");

ReactDOM.createRoot(container).render(<App />);
