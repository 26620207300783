import { createContext, useState } from "react";

import type { ReactNode } from "react";

interface IRadioPlayerContext {
  isPlaying: boolean;
  togglePlay: () => void;
  startPlayer: () => void;
  stopPlayer: () => void;
}

interface RadioPlayerProviderProps {
  children: ReactNode;
}

const RadioPlayerContext = createContext<IRadioPlayerContext | undefined>(
  undefined
);
export default RadioPlayerContext;

export const RadioPlayerProvider = ({ children }: RadioPlayerProviderProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => setIsPlaying((prev) => !prev);
  const startPlayer = () => setIsPlaying(true);
  const stopPlayer = () => setIsPlaying(false);

  return (
    <RadioPlayerContext.Provider
      value={{ isPlaying, togglePlay, startPlayer, stopPlayer }}
    >
      {children}
    </RadioPlayerContext.Provider>
  );
};
