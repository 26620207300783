import "./BannerDescription.scss";

import { Button, Container } from "react-bootstrap";

import RadioPlayerContext from "../RadioPlayerContext";
import Use3DAnimation from "./Animation3d";
import { useContext } from "react";

export default function BannerDescription() {
  const ref = Use3DAnimation();
  const context = useContext(RadioPlayerContext);

  return (
    <Container
      ref={ref}
      className="message-container fx height-full items-middle"
      style={{
        transform: "perspective(800px) rotateY(0deg) rotateX(0deg)",
        transition: "transform 0.3s ease 0s",
        height: "100%",
      }}
    >
      <Container className="element-column">
        <Container className="element-heading element-widget-wrap">
          <Container className="element-widget">
            <Container className="heading-title-container">
              <h3 className="heading-title">WELCOME TO</h3>
            </Container>
          </Container>
        </Container>

        <Container className="element-label element-widget-wrap">
          <Container className="element-widget-container">
            <Container className="element-caption">
              <Container className="element-caption-container">
                <h2 className="caption caption-txt">PINK RADIO</h2>
              </Container>
            </Container>
          </Container>
        </Container>

        <Container className="element-sub-label element-widget-wrap">
          <Container className="element-widget-container">
            <Container className="element-caption">
              <Container className="element-caption-container">
                <h2 className="heading-title">LETS HAVE FUN</h2>
              </Container>
            </Container>
          </Container>
        </Container>

        <Container className="element-widget-wrap element-widget-button">
          <Container className="element-widget-container">
            <p className="aligncenter">
              <Button
                className="element-btn element-btn--aligncenter"
                onClick={context?.startPlayer}
              >
                LISTEN NOW
              </Button>
            </p>
          </Container>
        </Container>
      </Container>
    </Container>
  );
}
