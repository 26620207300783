import "./Space3d.scss";

import * as THREE from "three";

import {
  EffectComposer,
  FilmPass,
  GlitchPass,
  HorizontalBlurShader,
  RGBShiftShader,
  RenderPass,
  ShaderPass,
  VerticalBlurShader,
} from "three/examples/jsm/Addons.js";
import { useEffect, useRef } from "react";

export default function Space3d() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const composeRef = useRef<EffectComposer | null>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const asteroidsRef = useRef<THREE.Object3D | null>(null);
  const planetRef = useRef<THREE.Object3D | null>(null);
  const starfieldRef = useRef<THREE.Points | null>(null);
  const highlightRingRef = useRef<THREE.Mesh | null>(null);

  const windowHalfXRef = useRef<number>(window.innerWidth / 2);
  const windowHalfYRef = useRef<number>(window.innerHeight / 2);
  const mouseYRef = useRef<number>(0);
  const mouseXRef = useRef<number>(0);

  // 3d scene setup
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    if (
      rendererRef.current ||
      cameraRef.current ||
      composeRef.current ||
      sceneRef.current ||
      asteroidsRef.current ||
      planetRef.current ||
      starfieldRef.current
    )
      return;

    const settingsData = {
      starsQuanmtity: 10000,
      asteroidsQuantity: 97,
      glitchPass: true,
      effectFilm: true,
      effectBlur: true,
      ambientLightIntensity: 1.0,
      light1i: 1.7,
      light2i: 2.6,
      light3i: 1.0,
      ambientLightColor: "#010101",
      light1: "#e70331",
      light2: "#d5003c",
      light3: "#ffffff",
      asteroidsMaterial: "0x999999",
      sphereMaterial: "0xdddddd",
    };

    const init = () => {
      const createAsteroids = () => {
        const asteroids = new THREE.Object3D();

        const col: number = parseInt(
          settingsData.asteroidsMaterial.split("#").join("0x"),
          16
        );
        const material = new THREE.MeshStandardMaterial({
          color: 1 * col,
          flatShading: true,
          roughness: 0.0,
        });
        for (let i = 0; i < settingsData.asteroidsQuantity; i++) {
          // all different sizes
          const sides = 3;
          const size = 0.2 + Math.random() / 2;
          const size2 = 0.1 + Math.random() / 3;
          const geometry = new THREE.CylinderGeometry(0, size, size2, sides);
          // geometry = new THREE.SphereBufferGeometry( size   , sides,  sides ); // spheres type
          const mesh = new THREE.Mesh(geometry, material);
          mesh.position
            .set(
              Math.random() - 0.5,
              Math.random() / 10 - 0.1,
              Math.random() - 0.5
            )
            .normalize();
          mesh.position.multiplyScalar(340 + Math.random() * 220);
          mesh.rotation.set(
            Math.random() * 2,
            Math.random() * 2,
            Math.random() * 2
          );
          mesh.scale.x = mesh.scale.y = mesh.scale.z = Math.random() * 50;
          asteroids.add(mesh);
        }

        return asteroids;
      };

      const createPlanet = () => {
        const sphere = new THREE.Object3D();
        const geometry = new THREE.IcosahedronGeometry(240, 1);
        const count = geometry.attributes.position.count;
        const colors: number[] = [];
        const color = new THREE.Color(
          1 * parseInt(settingsData.sphereMaterial.split("#").join("0x"), 16)
        );

        const materialSphere = new THREE.MeshStandardMaterial({
          color: color,
          flatShading: true,
          roughness: 0.0,
        });

        for (let i = 0; i < count; i += 3) {
          colors.push(color.r, color.g, color.b);
          colors.push(color.r, color.g, color.b);
          colors.push(color.r, color.g, color.b);
        }

        const mesh = new THREE.Mesh(geometry, materialSphere);
        geometry.setAttribute(
          "color",
          new THREE.Float32BufferAttribute(colors, 3)
        );
        sphere.add(mesh);

        return sphere;
      };

      const createStars = () => {
        // Vertex shader code
        const vertexShader = `
        uniform float maxDistance;
        varying float vAlpha;

        void main() {
          vAlpha = 1.0; // Set alpha to 1.0 initially
          gl_PointSize = 1.20; // Adjust the point size as needed
          vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
          gl_Position = projectionMatrix * mvPosition;
        }
        `;

        // Fragment shader code
        const fragmentShader = `
        uniform float maxDistance;
        varying float vAlpha;

        void main() {
          float distance = length(gl_PointCoord - vec2(0.75));
          float alpha = smoothstep(0.0, maxDistance, distance) * vAlpha;
          gl_FragColor = vec4(1.0, 1.0, 1.0, alpha); // Set the color to white and apply alpha
        }
        `;

        const starsGeometry = new THREE.BufferGeometry();
        const stars = new THREE.Points(starsGeometry);

        const vertices = [];
        for (let i = 0; i < settingsData.starsQuanmtity; i++) {
          const star: THREE.Vector3 = new THREE.Vector3(
            THREE.MathUtils.randFloatSpread(40000),
            THREE.MathUtils.randFloatSpread(1500),
            THREE.MathUtils.randFloatSpread(3000)
          );
          vertices.push(star.toArray());
        }

        starsGeometry.setAttribute(
          "position",
          new THREE.BufferAttribute(Float32Array.from(vertices.flat()), 3)
        );

        const starsMaterial = new THREE.ShaderMaterial({
          vertexShader,
          fragmentShader,
          transparent: true,
          depthWrite: false,
          uniforms: {
            maxDistance: { value: 0.5 }, // Adjust as needed
          },
        });

        stars.material = starsMaterial;

        return stars;
      };

      const renderer = new THREE.WebGLRenderer({
        alpha: true,
        canvas: canvasRef.current!,
      });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x000000, 1);
      rendererRef.current = renderer;

      // camera
      const camera = new THREE.PerspectiveCamera(
        100,
        window.innerWidth / window.innerHeight,
        1,
        2000
      );
      camera.position.z = 600;
      cameraRef.current = camera;

      const scene = new THREE.Scene();
      sceneRef.current = scene;

      // asteroids
      const asteroids = createAsteroids();
      asteroidsRef.current = asteroids;
      const asteroidsHolder = new THREE.Object3D();
      asteroidsHolder.add(asteroids);
      scene.add(asteroidsHolder);

      // sphere
      const planet = createPlanet();
      planetRef.current = planet;
      const planetHolder = new THREE.Object3D();
      planetHolder.add(planet);
      scene.add(planetHolder);

      // stars
      const starfield = createStars();
      starfield.position.z = 100;
      starfieldRef.current = starfield;
      scene.add(starfield);

      // Create a highlight ring
      const highlightTexture = new THREE.TextureLoader().load(
        "assets/flash.png"
      );
      const highlightMaterial = new THREE.MeshBasicMaterial({
        map: highlightTexture,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.25,
      });
      const highlightRing = new THREE.Mesh(
        new THREE.PlaneGeometry(8000, 8000),
        highlightMaterial
      );
      highlightRing.position.set(0, 0, -1000); // Position the highlight ring in front of the starfield
      highlightRingRef.current = highlightRing;
      scene.add(highlightRing);

      planetHolder.rotation.z = -0.5;
      asteroidsHolder.rotation.z = -0.5;

      // Lightings
      {
        // Ambient light
        scene.add(
          new THREE.AmbientLight(
            parseInt(settingsData.ambientLightColor.split("#").join("0x")),
            settingsData.ambientLightIntensity
          )
        );

        const light1 = new THREE.DirectionalLight(
          parseInt(settingsData.light1.split("#").join("0x")),
          settingsData.light1i
        );
        light1.position.set(-2, 1, -0.53);
        scene.add(light1);

        const light2 = new THREE.DirectionalLight(
          parseInt(settingsData.light2.split("#").join("0x")),
          settingsData.light2i
        );
        light2.position.set(-1, 2, -1.4);
        scene.add(light2);

        const light3 = new THREE.DirectionalLight(
          parseInt(settingsData.light3.split("#").join("0x")),
          settingsData.light3i
        );
        light3.position.set(1, -2, -1.5);
        scene.add(light3);
      }

      // Effects
      {
        const composer = new EffectComposer(renderer);
        composer.addPass(new RenderPass(scene, camera));
        composeRef.current = composer;

        // Blur
        if (settingsData.effectBlur) {
          const effectHBlur: ShaderPass = new ShaderPass(HorizontalBlurShader);
          const effectVBlur: ShaderPass = new ShaderPass(VerticalBlurShader);
          effectHBlur.uniforms["h"].value = 0.5 / window.innerWidth;
          effectVBlur.uniforms["v"].value = 0.5 / window.innerWidth;
          composer.addPass(effectHBlur);
          composer.addPass(effectVBlur);
        }

        // FilmPass
        if (settingsData.effectFilm) {
          const effectFilm: FilmPass = new FilmPass(0.5, false);
          composer.addPass(effectFilm);
        }

        // Glitch
        if (settingsData.glitchPass) {
          const glitchPass = new GlitchPass(30);
          composer.addPass(glitchPass);
          setTimeout(function () {
            glitchPass.enabled = false;
          }, 4000); // remove glitch after a while
        }

        // RGB
        const rgbEffect = new ShaderPass(RGBShiftShader);
        rgbEffect.uniforms["amount"].value = 0.0012;
        rgbEffect.renderToScreen = true; // add as last
        composer.addPass(rgbEffect);
      }
    };

    init();
  }, []);

  // Animation
  useEffect(() => {
    let lastFrameTime = performance.now();

    const countFps = () => {
      const currentTime = performance.now();
      const deltaTime = currentTime - lastFrameTime;
      lastFrameTime = currentTime;

      const fps = 1000 / deltaTime; // Calculate FPS

      return fps;
    };

    const animate = () => {
      requestAnimationFrame(animate);

      // You can then decide whether to animate based on the FPS value
      if (countFps() > 15) {
        const speed = 0.0005;
        // asteroids
        if (asteroidsRef.current) asteroidsRef.current.rotation.y += speed;
        // stars
        if (starfieldRef.current) starfieldRef.current.rotation.y += 0.00001;
        // sphere
        if (planetRef.current) planetRef.current.rotation.y += 0.0025;

        // camera
        if (cameraRef.current) {
          const delta = mouseXRef.current / windowHalfXRef.current;
          //const deltaY = mouseY / windowHalfY;
          const deltaAbs = 500 + delta * 100;

          cameraRef.current.position.z +=
            (deltaAbs - cameraRef.current.position.z) * speed;
          // planet.rotation.y +=delta * .005;
          // planet.rotation.x +=deltaY * .005;
          cameraRef.current.position.y +=
            (mouseYRef.current - cameraRef.current.position.y) * 0.0002;
          if (sceneRef.current)
            cameraRef.current.lookAt(sceneRef.current.position);

          // highlight ring
          if (highlightRingRef.current) {
            const cameraRotation = cameraRef.current.getWorldQuaternion(
              new THREE.Quaternion()
            );
            highlightRingRef.current.setRotationFromQuaternion(cameraRotation);
          }
        }

        if (composeRef.current) composeRef.current.render();
      }
    };

    animate();
  }, []);

  // Resize
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const onWindowResize = () => {
      windowHalfXRef.current = window.innerWidth / 4;
      windowHalfYRef.current = window.innerHeight / 4;

      if (cameraRef.current) {
        cameraRef.current.aspect = window.innerWidth / window.innerHeight;
        cameraRef.current.updateProjectionMatrix();
      }

      if (rendererRef.current)
        rendererRef.current.setSize(window.innerWidth, window.innerHeight);

      if (composeRef.current)
        composeRef.current.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  // Mouse move
  useEffect(() => {
    const onDocumentMouseMove = (event: MouseEvent) => {
      const mx = (event.clientX - windowHalfXRef.current) * 2;
      const my = (event.clientY - windowHalfYRef.current) * 2;

      mouseXRef.current = mx;
      mouseYRef.current = my;
      //const distance = 350 + (Math.abs(mx) / windowHalfX / 2) * 150;
    };

    document.addEventListener("mousemove", onDocumentMouseMove, false);

    return () => {
      document.removeEventListener("mousemove", onDocumentMouseMove);
    };
  }, []);

  return <canvas ref={canvasRef} className="webgl-canvas" />;
}
