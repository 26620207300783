import type { IChatUserSettings } from "../../../types/ChatUserSettings";
import styles from "./ChatUser.module.scss";

export interface ChatUserOwnProps {
  user: IChatUserSettings;
}

// Define the props type for the ChatHeader component
export type ChatUserProps = ChatUserOwnProps &
  React.HTMLAttributes<HTMLDivElement>;

export default function ChatUser({ user }: ChatUserProps) {
  const { username, colour, avatar, online } = user;
  return (
    <li className={"clearfix"}>
      <div
        className={styles.iconWrapper}
        style={{ border: `5px solid ${colour}`, backgroundColor: `${colour}` }}
      >
        <img src={avatar} alt="avatar" />
      </div>
      <div className={styles.about}>
        <div className={styles.name}>{username}</div>
        <div
          className={`${styles.status} ${
            online ? styles.online : styles.offline
          }`}
        >
          {online ? "online" : "offline"}
        </div>
      </div>
    </li>
  );
}
