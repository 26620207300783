import "./Diagonal.scss";

type SVGComponentProps = {
  fillClass: string;
} & React.SVGProps<SVGSVGElement>;

function SVGComponent({ fillClass, ...props }: SVGComponentProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 100"
      preserveAspectRatio="none"
      {...props}
    >
      <path className={fillClass} d="M0,6V0h1000v100L0,6z" />
    </svg>
  );
}

type DiagonalProps = {
  fillClass: string;
  className: string;
  dataNegative: boolean;
  dataFlip: boolean;
  style?: React.CSSProperties;
};

export default function Diagonal({
  fillClass,
  className,
  dataNegative,
  dataFlip,
  style,
}: DiagonalProps) {
  return (
    <div
      className={className}
      data-negative={dataNegative}
      data-flip={dataFlip}
      style={style}
    >
      <SVGComponent fillClass={fillClass} className="svg-shape" />
    </div>
  );
}
