import { useEffect, useRef } from "react";

export default function Use3DAnimation() {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const item = ref.current;
      if (!item) return;

      const screenWidth = window.innerWidth / 2;
      const screenHeight = window.innerHeight / 2;
      const centroX = e.clientX - screenWidth;
      const centroY = screenHeight - (e.clientY + 13);
      const degX = centroX * 0.12;
      const degY = centroY * 0.048;

      item.style.transform = `perspective(800px) rotateY(${
        degX * 0.5
      }deg) rotateX(${degY}deg)`;
      item.style.transition = "none";
    };

    const handleReset = () => {
      const item = ref.current;
      if (!item) return;

      item.style.transform = "perspective(800px) rotateY(0deg) rotateX(0deg)";
      item.style.transition = "transform 0.3s ease";
    };

    document.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseleave", handleReset);
    window.addEventListener("mouseout", handleReset);
    window.addEventListener("blur", handleReset);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseleave", handleReset);
      window.removeEventListener("mouseout", handleReset);
      window.removeEventListener("blur", handleReset);
    };
  }, []);

  return ref;
}
