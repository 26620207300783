import type {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from "firebase/firestore";

import type { IChatMessage } from "../../types/ChatMessage";
import type { IChatUserSettings } from "../../types/ChatUserSettings";

// Firestore data converter for message type
export const MessageConverter: FirestoreDataConverter<IChatMessage> = {
  toFirestore(message: IChatMessage): DocumentData {
    return {
      content: message.content,
      senderId: message.senderId,
      senderName: message.senderName,
      avatarUrl: message.avatarUrl,
      colour: message.colour,
      timestamp: message.timestamp,
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): IChatMessage {
    const data = snapshot.data() as {
      content: string;
      senderId: string;
      senderName: string;
      avatarUrl: string;
      colour: string;
      timestamp: { toDate: () => Date };
    };
    return {
      id: snapshot.id,
      content: data.content,
      senderId: data.senderId,
      senderName: data.senderName,
      avatarUrl: data.avatarUrl,
      colour: data.colour,
      timestamp: data.timestamp.toDate(), // Convert Firestore timestamp to JavaScript Date
    };
  },
};

export const AvatarConverter: FirestoreDataConverter<IChatUserSettings> = {
  toFirestore(user: IChatUserSettings): DocumentData {
    return {
      id: user.id,
      username: user.username,
      colour: user.colour,
      avatar: user.avatar,
      role: user.role,
      online: user.online,
      timestamp: user.timestamp,
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): IChatUserSettings {
    const data = snapshot.data() as {
      username: string;
      colour: string;
      avatar: string;
      role: string;
      online: boolean;
      timestamp: { toDate: () => Date };
    };

    return {
      id: snapshot.id,
      username: data.username,
      colour: data.colour,
      avatar: data.avatar,
      role: data.role,
      online: data.online,
      timestamp: data.timestamp.toDate(), // Convert Firestore timestamp to JavaScript Date
    };
  },
};
