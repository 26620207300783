import "./MenuBar.scss";

import { useEffect, useState } from "react";

import type IMenuItemProps from "../interfaces/MenuItemProps";
import pinklogo from "../../public/assets/pinklogo9.png";

function MenuItem({ id, label, href, current, subMenu }: IMenuItemProps) {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    window.history.pushState(null, "", href);
    if (!href || href === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const section = document.getElementById(href.substring(1));
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <li
      id={id}
      className={`menu-item ${current ? "current-menu-item" : ""} ${
        subMenu ? "menu-item-has-children" : ""
      }`}
      onMouseEnter={toggleSubMenu}
      onMouseLeave={toggleSubMenu}
    >
      <a
        href={href}
        onClick={handleClick}
        aria-current={current ? "page" : undefined}
      >
        {label}
      </a>
      {subMenu && (
        <ul className={`sub-menu ${isSubMenuOpen ? "open" : ""}`}>
          {subMenu.map((item) => (
            <li id={item.id} key={item.id} className="menu-item sub-menu">
              <a href={item.href}>{item.label}</a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}

function MenuLogo() {
  return (
    <a href="" className="site-logo">
      <img src={pinklogo} className="img-responsive" alt="Home" />
    </a>
  );
}

function SideMenu() {
  return (
    <div
      id="nav"
      style={{ overflow: "hidden", width: "320px", height: "100%" }}
    >
      <div className="qw-scroller">
        <div className="qw-menucontainer">
          <ul id="qwtoggle" className="sidebar-menu toggle">
            <MenuItem id="home-item" label="Home" current />

            <MenuItem id="chat-item" label="Chat" href="" />
            <MenuItem id="podcast-item" label="Podcast" href="" />
            <MenuItem id="timeline-item" label="Timeline" href="" />
            <MenuItem id="music-item" label="Music" href="" />
            <MenuItem id="contact-item" label="Contact" href="" />
          </ul>
          <div className="qw-socialicons">
            <a href="" className="qw_social">
              <span className="qticon-beatport"></span>
            </a>
            <a href="" className="qw_social">
              <span className="qticon-facebook"></span>
            </a>
            <a href="" className="qw_social">
              <span className="qticon-instagram"></span>
            </a>
            <a href="" className="qw_social">
              <span className="qticon-itunes"></span>
            </a>
            <a href="" className="qw_social">
              <span className="qticon-youtube"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
/*
function MenuFunctions() {
  return (
    <div className="menu-functions">
      <div className="sharepage-top">
        <div className="sharepage-top-icon-container">
          <a
            href="#"
            className="qw-shareall"
            id="qwShareall"
            data-toggleclass="open"
            data-target="qwShareallC"
            data-toggleclass2="hidden-xs"
            data-target2="qwLogo"
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 32 32"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path>
            </svg>
          </a>
        </div>
        <div
          className="qw-sharepage-top qw-animated sharepage-top-contents"
          id="qwShareallC"
        >
          <div className="sharepage-top-contents-inner">
            <a
              href="#"
              data-type="twitter"
              data-url=""
              data-description=""
              data-via
              data-media
              className="prettySocial"
            >
              <FaSpotify />
            </a>
            <a
              href="#"
              id="qtFacebookShareButton"
              data-type="facebook"
              data-url=""
              data-description=""
              data-media
              className="prettySocial"
            >
              <FaFacebookF />
            </a>
            <a
              href="#"
              data-type="instagram"
              data-url=""
              data-description=""
              className="prettySocial"
              data-media
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
*/
export default function MenuBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const bodyElement = document.getElementById("app-body");
    bodyElement?.classList.add("qw-menutype1");
  }, [isMenuOpen]);

  useEffect(() => {
    const bodyElement = document.getElementById("app-body");
    const buttonElement = document.getElementById("menu-button");
    if (isMenuOpen) {
      buttonElement?.classList.add("menuopen");
      bodyElement?.classList.add("menu-open");
    } else {
      buttonElement?.classList.remove("menuopen");
      bodyElement?.classList.remove("menu-open");
    }
  }, [isMenuOpen]);

  return (
    <section id="menu-bar" className="">
      <div className="header-fixed animated" id="qwFixedHeader">
        <div id="menu-bar-container" className="menu-contents">
          <MenuLogo />

          <nav id="viceMenunav" className="navigation-bar">
            <div className="menu-bar-container">
              <ul
                id="vice-menubar"
                className={`menu-bar ${isMenuOpen ? "open" : ""}`}
              >
                <MenuItem id="menu-item-home" label="Home" href="/" />
                <MenuItem
                  id="menu-item-timeline"
                  label="On Air"
                  href="/on-air"
                />
                <MenuItem id="menu-item-chat" label="Chat" href="/chat" />
                <MenuItem
                  id="menu-item-podcast"
                  label="Podcast"
                  href="/podcast"
                />
                <MenuItem id="menu-item-music" label="Music" href="" />
                <MenuItem
                  id="menu-item-contact"
                  label="Contact"
                  href="/contact"
                />
              </ul>
            </div>
          </nav>
          <div className="menu-icon menu__icon--desktop">
            <a
              href="#"
              className="menu-icon"
              id="qwMenuToggle"
              onClick={toggleMenu}
            >
              <hr className="menu-icon__a" />
              <hr className="menu-icon__b" />
              <hr className="menu-icon__c" />
            </a>
          </div>

          {/*<SideMenuButton />*/}
          {/*<MenuFunctions />*/}
        </div>
      </div>
      <div
        id="side-menu"
        className="slimScrollDiv menu-scroll"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "320px",
          height: "100%",
        }}
      >
        <SideMenu />
      </div>
    </section>
  );
}
