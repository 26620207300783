import "./ChatUserSettingsForm.scss";

import { FaRegUserCircle, FaUser } from "react-icons/fa";
import { useEffect, useState } from "react";

import type { IChatUserSettings } from "../../types/ChatUserSettings";
import { IoColorPaletteSharp } from "react-icons/io5";

interface ChatUserSettingsFormProps {
  onSubmit: (settings: IChatUserSettings) => void;
}

const getRandomHexColor = (): string => {
  // Generate a random number between 0 and 16777215 (0xFFFFFF)
  const randomNumber = Math.floor(Math.random() * 16777215);
  const hexString = randomNumber.toString(16).padStart(6, "0");

  return `#${hexString}`;
};

export default function ChatUserSettingsForm({
  onSubmit,
}: ChatUserSettingsFormProps) {
  const [username, setUsername] = useState("");
  const [colour, setColour] = useState(getRandomHexColor());
  const [avatar, setAvatar] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(username.trim() === "");
  }, [username]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      username,
      colour,
      avatar,
      id: "",
    });
  };

  return (
    <section className="chat-user-settings">
      <div className="row">
        <div className="col">
          <div className="no-border card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <h1>Join our chat</h1>
                <div className="invalid-credentials mb-2"></div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <FaUser />
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <IoColorPaletteSharp />
                    </div>
                    <input
                      className="form-control"
                      style={{ maxWidth: "50px" }}
                      type="color"
                      value={colour}
                      onChange={(e) => setColour(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <FaRegUserCircle />
                    </div>
                    <input
                      className="form-control"
                      type="url"
                      value={avatar}
                      onChange={(e) => setAvatar(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <button
                  className="btn btn-primary btn-sm w-100 mt-2"
                  type="submit"
                  disabled={isDisabled}
                >
                  Join
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
