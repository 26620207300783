import { forwardRef, useEffect, useRef } from "react";

import type { IChatMessage } from "../../../types/ChatMessage";
import type { IChatUserSettings } from "../../../types/ChatUserSettings";
import Message from "./Message";
import MessageList from "./MessageList";
import styles from "./ChatHistory.module.scss";

// Define an empty props interface for the ChatHistory component
export interface ChatHistoryOwnProps {
  messages?: IChatMessage[];
  user: IChatUserSettings;
}

// Define the props type for the ChatHistory component
export type ChatHistoryProps = ChatHistoryOwnProps &
  React.HTMLAttributes<HTMLDivElement> & {
    ref?: React.Ref<HTMLDivElement>;
    children?: React.ReactNode;
  };

// Define the ChatHistory component
export const ChatHistory = forwardRef<HTMLDivElement, ChatHistoryProps>(
  (props, ref) => {
    const { children, messages, user, ...rest } = props;
    const containerRef = useRef<HTMLDivElement>(null);

    // this will ensure that the correct container is passed to the message list so we can properly scroll to location
    useEffect(() => {
      const scrollContainer =
        ref && typeof ref !== "function" ? ref.current : containerRef.current;
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }, [messages, ref]);

    return (
      <div
        ref={ref || containerRef}
        className={styles.chatHistoryContainer}
        {...rest}
      >
        <MessageList>
          {messages &&
            messages.map((msg, index) => (
              <Message key={index} message={msg} user={user} />
            ))}

          {children}
        </MessageList>
      </div>
    );
  }
);

// Add display name to ChatHistory
ChatHistory.displayName = "ChatHistory";

export default ChatHistory;
