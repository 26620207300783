import { useEffect, useRef } from "react";

import styles from "./ChatMessage.module.scss";

export interface ChatMessageOwnProps {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  sendOnReturnDisabled?: boolean;
  sendDisabled?: boolean;
  fancyScroll?: boolean;
  activateAfterChange?: boolean;
  autoFocus?: boolean;
  onChange?: (
    innerHtml: string,
    textContent: string,
    innerText: string,
    nodes: NodeList
  ) => void;
  onSend?: (
    innerHtml: string,
    textContent: string,
    innerText: string,
    nodes: NodeList
  ) => void;
  sendButton?: boolean;
  attachButton?: boolean;
  attachDisabled?: boolean;
  onAttachClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
}

// omit div element common attributes with own props
export type ChatMessageProps = ChatMessageOwnProps &
  Omit<
    React.HTMLAttributes<HTMLDivElement>,
    "children" | keyof ChatMessageOwnProps
  >;

export default function ChatMessage({
  value = "",
  placeholder = "Type a message...",
  disabled = false,
  sendOnReturnDisabled = false,
  sendDisabled = false,
  fancyScroll = false,
  activateAfterChange = false,
  autoFocus = false,
  onChange,
  onSend,
  sendButton = true,
  attachButton = false,
  attachDisabled = false,
  onAttachClick,
  ...rest
}: ChatMessageProps) {
  const editorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Implement auto focus
  useEffect(() => {
    if (autoFocus && editorRef.current) {
      editorRef.current.focus();
    }
  }, [autoFocus]);

  // Implement fancy scroll
  useEffect(() => {
    if (fancyScroll && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [fancyScroll, value]);

  const onInputEvent = () => {
    if (editorRef.current) {
      const innerHtml = editorRef.current.innerHTML;
      const textContent = editorRef.current.textContent || "";
      const innerText = editorRef.current.innerText || "";
      const nodes = editorRef.current.childNodes;

      const selection = window.getSelection();
      const range = selection?.getRangeAt(0).cloneRange();

      if (onChange) {
        onChange(innerHtml, textContent, innerText, nodes);
      }

      if (range && selection) {
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(editorRef.current);
        preCaretRange.setEnd(range.startContainer, range.startOffset);
        const caretOffset = preCaretRange.toString().length;

        setTimeout(() => {
          const newRange = document.createRange();
          let charCount = 0;
          const nodeStack: Node[] = [editorRef.current!];
          let node: Node | undefined;

          while ((node = nodeStack.pop())) {
            if (node.nodeType === 3) {
              // TEXT_NODE
              const nextCharCount = charCount + node.textContent!.length;
              if (caretOffset <= nextCharCount) {
                newRange.setStart(node, caretOffset - charCount);
                newRange.setEnd(node, caretOffset - charCount);
                break;
              }
              charCount = nextCharCount;
            } else if (node.nodeType === 1) {
              let i = node.childNodes.length;
              while (i--) {
                nodeStack.push(node.childNodes[i]);
              }
            }
          }

          selection.removeAllRanges();
          selection.addRange(newRange);
        }, 0);
      }

      if (activateAfterChange && editorRef.current) {
        editorRef.current.focus();
      }
    }
  };

  const onClickEventSend = () => {
    if (editorRef.current && onSend) {
      const innerHtml = editorRef.current.innerHTML;
      const textContent = editorRef.current.textContent || "";
      const innerText = editorRef.current.innerText || "";
      const nodes = editorRef.current.childNodes;

      onSend(innerHtml, textContent, innerText, nodes);
      editorRef.current.innerHTML = "";
    }
  };

  const onKeyPressEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !sendOnReturnDisabled) {
      event.preventDefault();
      onClickEventSend();
    }
  };

  return (
    <div
      className={`${styles.chatMessage} clearfix`}
      {...rest}
      ref={containerRef}
    >
      <div className={styles.chatMessageInput}>
        <div className={styles.inputContent}>
          <div
            className={styles.inputContentEditor}
            ref={editorRef}
            contentEditable={!disabled}
            data-placeholder={placeholder}
            onInput={onInputEvent}
            onKeyPress={onKeyPressEvent}
            dangerouslySetInnerHTML={{ __html: value }}
          ></div>
        </div>
      </div>
      {sendButton && (
        <div className={styles.chatMessageSend}>
          <button
            className={styles.chatMessageSendButton}
            onClick={onClickEventSend}
            disabled={sendDisabled}
          ></button>
        </div>
      )}
      {attachButton && (
        <div className={styles.chatMessageAttach}>
          <button
            className={styles.chatMessageAttachButton}
            onClick={onAttachClick}
            disabled={attachDisabled}
          ></button>
        </div>
      )}
    </div>
  );
}
