import "./Contacts.scss";
import emailjs from "emailjs-com";

import { useEffect, useState, type ChangeEvent, type FormEvent } from "react";

import type ISectionProps from "../interfaces/SectionProps";

export default function ContactSection({ id }: ISectionProps) {
  const [width, setWidth] = useState(window.innerWidth);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatusMessage("Sending...");

    const isErrorWithText = (error: any): error is { text: string } => {
      return (error as { text: string }).text !== undefined;
    };

    emailjs
      .send(
        import.meta.env.PINK_EMAILJS_SERVICE_ID,
        import.meta.env.PINK_EMAILJS_TEMPLATE_ID,
        formData,
        import.meta.env.PINK_EMAILJS_USER_ID
      )
      .then((result) => {
        console.log(result.text);
        setStatusMessage("Email sent successfully!");
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        if (isErrorWithText(error)) {
          console.error(error.text);
        } else {
          console.error(error);
        }
        setStatusMessage("Failed to send email. Please try again.");
      });
  };

  return (
    <section
      id={id}
      className="contact-area section-element section-height-full"
      style={{ width: width }}
    >
      <div className="background-overlay"></div>

      <div className="section-container">
        <div className=" section-element column col-40">
          <h2 className="caption caption-txt">- CONTACTS -</h2>
        </div>

        <div className="widget-wrap column col-60">
          <div className="section-element  " style={{ width: "100%" }}>
            <div className="form-container widget-container">
              <div className="screen-reader-response">
                <p role="status" aria-live="polite" aria-atomic="true">
                  {statusMessage}
                </p>
                <ul></ul>
              </div>

              <form
                id="emailForm"
                action=""
                method="post"
                className="init"
                aria-label="Contact form"
                onSubmit={handleSubmit}
              >
                <div style={{ display: "none" }}>
                  <input size={50} type="hidden" name="_wpcf7" value="551" />
                  <input size={50} type="hidden" name="version" value="5.9.5" />
                  <input size={50} type="hidden" name="locale" value="en_US" />
                  <input
                    type="hidden"
                    name="unit_tag"
                    value="f551-p539"
                    size={50}
                  />
                  <input
                    size={50}
                    type="hidden"
                    name="container_post"
                    value="539"
                  />
                  <input
                    size={50}
                    type="hidden"
                    name="posted_data_hash"
                    value=""
                  />
                </div>
                <p>
                  <label>
                    Name <sup className="text-danger">*</sup>
                    <br />
                    <span className="" data-name="your-name">
                      <input
                        className="responsive-input"
                        aria-required="true"
                        aria-invalid="false"
                        value={formData.name}
                        type="text"
                        name="name"
                        id="name"
                        size={55}
                        placeholder="your name"
                        required
                        onChange={handleInputChange}
                      />
                    </span>
                  </label>
                </p>
                <p>
                  <label>
                    Email <sup className="text-danger">*</sup>
                    <br />
                    <span className="" data-name="your-email">
                      <input
                        className="responsive-input"
                        aria-required="true"
                        aria-invalid="false"
                        type="email"
                        name="email"
                        id="email"
                        size={55}
                        pattern="[^ @]*@[^ @]*"
                        placeholder="your email address"
                        required
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </span>
                  </label>
                </p>
                <p>
                  <label>
                    How can we help?
                    <br />
                    <span className="" data-name="your-message">
                      <textarea
                        className="responsive-input"
                        aria-invalid="false"
                        name="message"
                        cols={55}
                        rows={10}
                        placeholder="...tell us about the station"
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                    </span>
                  </label>
                </p>
                <p>
                  <input className="form-submit" type="submit" value="Send" />
                </p>
                <div className="" aria-hidden="true"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
