import { forwardRef } from "react";

// Define the MessageListOwnProps interface
export interface MessageListOwnProps {
  typingIndicator?: React.ReactNode;
  loading?: boolean;
  loadingMore?: boolean;
  loadingMorePosition?: "top" | "bottom";
  onYReachStart?: (container: HTMLUListElement) => void;
  onYReachEnd?: (container: HTMLUListElement) => void;
  autoScrollToBottom?: boolean;
  autoScrollToBottomOnMount?: boolean;
  scrollBehavior?: "auto" | "smooth";
}

// Define the MessageListProps type
export type MessageListProps = MessageListOwnProps &
  React.HTMLAttributes<HTMLUListElement> & {
    ref?: React.Ref<HTMLUListElement>;
    children?: React.ReactNode;
  };

// Define the MessageListContent component
const MessageListContent = forwardRef<
  HTMLLIElement,
  React.HTMLAttributes<HTMLLIElement>
>((props, ref) => <li ref={ref} {...props} />);

// Add display name to MessageListContent
MessageListContent.displayName = "MessageListContent";

// Define the MessageList component
const MessageListComponent = forwardRef<HTMLUListElement, MessageListProps>(
  (props, ref) => {
    const {
      typingIndicator,
      loading,
      loadingMore,
      loadingMorePosition,
      children,
      ...rest
    } = props;

    return (
      <ul ref={ref} {...rest}>
        {loading && <li>Loading...</li>}
        {loadingMore && loadingMorePosition === "top" && (
          <li>Loading more...</li>
        )}
        {children}
        {loadingMore && loadingMorePosition === "bottom" && (
          <li>Loading more...</li>
        )}
        {typingIndicator && <li>{typingIndicator}</li>}
      </ul>
    );
  }
);

// Add display name to MessageListComponent
MessageListComponent.displayName = "MessageList";

// Explicitly define the Content property on the MessageListComponent
interface MessageListComponentType
  extends React.ForwardRefExoticComponent<
    MessageListProps & React.RefAttributes<HTMLUListElement>
  > {
  Content: typeof MessageListContent;
}

const MessageList = MessageListComponent as MessageListComponentType;
MessageList.Content = MessageListContent;

export default MessageList;
