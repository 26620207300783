import "./PageShell.scss";

import RadioPlayer from "../components/RadioPlayer";
import { Suspense, type PropsWithChildren } from "react";
import { Spinner } from "react-bootstrap";
import SocialIconsBar from "../components/SocialIconsBar";

export default function PageShell({ children }: PropsWithChildren) {
  return (
    <>
      <Suspense
        fallback={
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        }
      >
        {children}
        <SocialIconsBar />
        <RadioPlayer />
      </Suspense>
    </>
  );
}
