import { Avatar, Message as ChatMessage } from "@chatscope/chat-ui-kit-react";

import type IChatMessageProps from "../../../types/ChatMessage";
import styles from "./Message.module.scss";

const USER_ADMIN = "admin";
const USER_ME = "me";
const USER_OTHER = "other";
const DIRECTION_RIGHT = "right";
const DIRECTION_LEFT = "left";

export default function Message({ message, user }: IChatMessageProps) {
  const { content, id, senderName, timestamp, senderId } = message;
  const currentUser = user.id;
  const admin = senderId.toLowerCase() == USER_ADMIN;
  const who = admin
    ? USER_ADMIN
    : senderId === currentUser
    ? USER_ME
    : USER_OTHER;
  const direction = senderId === currentUser ? DIRECTION_RIGHT : DIRECTION_LEFT;

  const isUserMe = (who: string): boolean => {
    return who === USER_ME;
  };
  const formatTimestamp = (timestamp: Date): string => {
    const now = new Date();
    const date = new Date(timestamp);

    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const time = `${hours}:${minutes}`;

    if (isToday) {
      return `${time}, Today`;
    }

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${time}, ${day}/${month}/${year}`;
  };

  return (
    <li id={id} className={`${who === USER_ME ? styles.clearfix : ""}`}>
      {!admin && (
        <div
          className={`${styles["message-head"]} ${
            isUserMe(who) ? styles["align-right"] : styles["align-left"]
          }`}
        >
          {!isUserMe(who) && <span>{senderName}</span>}
          <span className={`${styles["message-head-time"]}`}>
            {formatTimestamp(timestamp)}
          </span>
          {isUserMe(who) && <span>{senderName}</span>}
        </div>
      )}

      <div
        className={`${styles.message} ${styles[`message-${who}`]} ${
          admin ? "" : styles[`float-${direction}`]
        }`}
        //style={{ "--message-bg-color": colour } as React.CSSProperties}
      >
        {content}
      </div>
    </li>
  );
}

export function MessageOld({ message, user }: IChatMessageProps) {
  const { content, id, senderName, timestamp, senderId, avatarUrl } = message;
  const currentUser = user.id;
  const admin = senderId.toLowerCase() == "admin";
  const direction = senderId === currentUser ? "outgoing" : "incoming";

  return (
    <div
      key={id}
      style={{
        display: "flex",
        flexDirection: direction === "outgoing" ? "row-reverse" : "row",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      {!admin && (
        <Avatar
          style={{
            marginLeft: direction === "outgoing" ? "10px" : "0",
            marginRight: direction === "incoming" ? "10px" : "0",
          }}
          src={avatarUrl}
          name={senderName}
        />
      )}
      <div
        style={{
          maxWidth: "70%",
          display: "flex",
          flexDirection: "column",
          alignItems: direction === "outgoing" ? "flex-end" : "flex-start",
          ...(admin && { width: "70% !important" }),
        }}
      >
        <ChatMessage
          model={{
            message: content,
            sentTime: timestamp.toString(),
            sender: senderName,
            direction: direction,
            position: "single", // or "first", "last", "single" based on your logic
          }}
        />
      </div>
    </div>
  );
}
