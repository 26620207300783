import "./Timeline.scss";
import "aos/dist/aos.css";

import { useEffect, useState } from "react";

import AOS from "aos";
import type ISectionProps from "../interfaces/SectionProps";

export default function Timeline({ id }: ISectionProps) {
  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 2000, // Duration of the animation in milliseconds
    });
  }, []);

  return (
    <section
      id={id}
      className="timeline-area section-element section-height-full"
      style={{ width: width }}
    >
      <div className="">
        <div className="timeline-title">
          <h2
            className="caption caption-txt"
            style={{ width: "100%", textAlign: "center", zIndex: "10" }}
          >
            - ON AIR -
          </h2>
        </div>
        <section className="pb-0" id="events">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 col-12 mt-3 mb-lg-5 bgimage aos-init aos-animate"
                data-aos="zoom-out-up"
                data-aos-delay="300"
                data-aos-easing="ease-in-sine"
              >
                <div className="container-message">
                  <h2 className="mb-3" data-aos="fade-up">
                    Stay tuned...
                  </h2>
                  <div
                    className="container-fluid main-message"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <p className="me-4">
                      Welcome to the airwaves of{" "}
                      <a rel="nofollow" href="" target="_parent">
                        Pink Radio
                      </a>
                      , where vibrant beats and infectious melodies are about to
                      take over your senses! Get ready to immerse yourself in a
                      world of pure musical bliss, where every note is a burst
                      of joy and every rhythm is a reason to dance.
                    </p>
                    <p className="me-4">
                      Stay tuned, because we&apos;re about to fill your speakers
                      with a symphony of pink perfection. Let the music surround
                      you and let Pink Radio be your melodic sanctuary.
                    </p>
                    <p className="me-4">
                      Get ready to paint the airwaves pink!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 mt-3 mb-lg-5">
                <div
                  className="card"
                  data-aos="zoom-out-up"
                  data-aos-delay="300"
                  data-aos-easing="ease-in-sine"
                >
                  <div className="card-body">
                    <h4
                      className="card-title"
                      data-aos="fade-right"
                      data-aos-delay="400"
                    >
                      Timeline
                    </h4>
                    <div
                      className="events-timeline"
                      data-aos="flip-left"
                      data-aos-easing="ease-in-sine"
                      data-aos-delay="1000"
                    >
                      <ul className="timeline">
                        <li className="event" data-date="00:00 - 23:59pm">
                          <h3>Music non stop</h3>
                          <p>
                            Get ready for an uninterrupted music extravaganza!
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
