import "./SocialIconsBar.scss";

export default function SocialIconsBar() {
  return (
    <section id="social-icons">
      <div className="social-icons-container">
        <a href="https://facebook.com" target="_blank" rel="noreferrer">
          <span className="facebook-icon"></span>
        </a>
        <a href="https://instagram.com/" target="_blank" rel="noreferrer">
          <span className="instagram-icon"></span>
        </a>
        <a href="https://spotify.com/" target="_blank" rel="noreferrer">
          <span className="spotify-icon"></span>
        </a>
        <a href="https://youtube.com/" target="_blank" rel="noreferrer">
          <span className="youtube-icon"></span>
        </a>
      </div>
    </section>
  );
}
