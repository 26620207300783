import "./Podcast.scss";

import GetLatestEpisodesAsync, { spotifyConfig } from "../utils/spotifyqueries";
import { useEffect, useRef, useState } from "react";

import type ISectionProps from "../interfaces/SectionProps";
import PodCastCarousel from "../components/PodcastCarousel";
import type SpotifyEpisode from "../../types/Spotify";

export default function Podcast({ id }: ISectionProps) {
  const [width, setWidth] = useState(window.innerWidth);
  const [episodes, setEpisodes] = useState<SpotifyEpisode[]>([]);
  const sectionRef = useRef<HTMLElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const adjustHeight = () => {
    if (sectionRef.current && containerRef.current) {
      sectionRef.current.style.height = `${containerRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const latestEpisodes = await GetLatestEpisodesAsync(
          spotifyConfig.showId,
          +spotifyConfig.showEpisodesMax
        );
        setEpisodes(latestEpisodes);
      } catch (error) {
        console.error("Failed to fetch episodes", error);
      }
    };

    fetchEpisodes().catch((error) => {
      console.error("An error occurred in fetchEpisodes:", error);
    });
  }, []);

  // Hook to handle dynamic height adjustment
  useEffect(() => {
    adjustHeight();

    // Create a ResizeObserver to monitor changes in the container's size
    const resizeObserver = new ResizeObserver(() => {
      adjustHeight();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [episodes, width]);

  return (
    <section id={id} className="podcast-area section-element " ref={sectionRef}>
      <div className="" ref={containerRef}>
        <div className="section-title">
          <h2
            className="caption caption-txt"
            style={{ width: "100%", textAlign: "center", zIndex: "10" }}
          >
            - PODCASTS -
          </h2>
        </div>

        <div className="pb-0" id="pods">
          <div className="container" style={{ maxWidth: width }}>
            <div className="row">
              <div className="col-lg-12 col-12 mt-3 mb-lg-5">
                <PodCastCarousel
                  episodes={episodes}
                  toShow={+spotifyConfig.showEpisodesPerRow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
