import styles from "./ChatHeader.module.scss";

export interface ChatHeaderOwnProps {
  icon: string;
  name: string;
  timestamp?: Date;
}

// Define the props type for the ChatHeader component
export type ChatHeaderProps = ChatHeaderOwnProps &
  React.HTMLAttributes<HTMLDivElement> & {
    children?: React.ReactNode;
  };

export default function ChatHeader({
  children,
  icon,
  name,
  timestamp,
}: ChatHeaderProps) {
  return (
    <div className={`${styles.chatHeader} clearfix`}>
      <div className={styles.iconWrapper}>
        <img src={icon} alt="avatar" />
      </div>
      <div className={styles.chatAbout}>
        <div className={styles.chatWith}>Chat with {name}</div>
        {timestamp && (
          <div className={styles.chatLastSentMessage}>
            Last message sent {timestamp?.toLocaleString() ?? ""}
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
