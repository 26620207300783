import { StrictMode, useEffect, useState } from "react";

import ChatRoom from "./ChatRoom";
import ContactSection from "./Contacts";
import Home from "./Home";
import MenuBar from "../components/MenuBar";
import PageShell from "./PageShell";
import Podcast from "./Podcast";
import { RadioPlayerProvider } from "../components/RadioPlayerContext";
import Timeline from "./Timeline";

export default function App() {
  const [currentPath, setCurrentPath] = useState<string>(
    window.location.pathname
  );

  const scrollToSection = (pathname: string) => {
    const sectionId =
      pathname === "/" || pathname === "" ? "home" : pathname.substring(1);
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      const path = window.location.pathname;
      setCurrentPath(path);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sectionId = queryParams.get("section");

    if (sectionId && document.getElementById(sectionId)) {
      scrollToSection(sectionId);
    } else if (
      currentPath !== "/" &&
      !document.getElementById(currentPath.substring(1))
    ) {
      const sectionId = currentPath.substring(1);
      window.history.replaceState(null, "", `/${sectionId}`);
      setCurrentPath("/");
    } else {
      scrollToSection(currentPath.substring(1) || "home");
    }
  }, [currentPath]);

  return (
    <StrictMode>
      <RadioPlayerProvider>
        {(() => {
          switch (currentPath) {
            case "/":
            case "":
              return (
                <PageShell>
                  <MenuBar />
                  <Home id="home" />
                  <Timeline id="on-air" />
                  <ChatRoom id="chat" />
                  <Podcast id="podcast" />
                  <ContactSection id="contact" />
                </PageShell>
              );
          }
        })()}
      </RadioPlayerProvider>
    </StrictMode>
  );
}
