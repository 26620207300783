// Import the functions you need from the SDKs you need

import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const {
  PINK_FIREBASE_API_KEY,
  PINK_FIREBASE_AUTH_DOMAIN,
  PINK_FIREBASE_PROJECT_ID,
  PINK_FIREBASE_STORAGE_BUCKET,
  PINK_FIREBASE_MESSAGING_SENDER_ID,
  PINK_FIREBASE_APP_ID,
} = import.meta.env as Record<string, string>;

const firebaseConfig = {
  apiKey: PINK_FIREBASE_API_KEY,
  authDomain: PINK_FIREBASE_AUTH_DOMAIN,
  projectId: PINK_FIREBASE_PROJECT_ID,
  storageBucket: PINK_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: PINK_FIREBASE_MESSAGING_SENDER_ID,
  appId: PINK_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
