import ChatUser from "./ChatUser";
import type { IChatUserSettings } from "../../../types/ChatUserSettings";
import { forwardRef } from "react";
import styles from "./ChatUserList.module.scss";

// Define an empty props interface for the ChatHistory component
export interface ChatUserListOwnProps {
  users?: IChatUserSettings[];
}

// Define the props type for the ChatHistory component
export type ChatUserListProps = ChatUserListOwnProps &
  React.HTMLAttributes<HTMLDivElement> & {
    ref?: React.Ref<HTMLDivElement>;
  };

// Define the ChatHistory component
export const ChatUserList = forwardRef<HTMLDivElement, ChatUserListProps>(
  (props, ref) => {
    const { users, ...rest } = props;

    return (
      <div ref={ref} className={styles.chatUserListContainer} {...rest}>
        <ul>
          {users &&
            users.map((user, index) => <ChatUser key={index} user={user} />)}
        </ul>
      </div>
    );
  }
);

// Add display name to ChatUserList
ChatUserList.displayName = "ChatUserList";

export default ChatUserList;
