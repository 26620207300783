import "./Home.scss";

import { useEffect, useState } from "react";

import BannerDescription from "../components/assets/BannerDescription";
import Diagonal from "../components/assets/Diagonal";
import type ISectionProps from "../interfaces/SectionProps";
import Space3d from "../components/assets/Space3d";

//import BannerDescription from "./BannerDescription";

export default function Home({ id }: ISectionProps) {
  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return (
    <section
      id={id}
      className="home-section"
      style={{ width: width, left: "-10px" }}
    >
      <div id="webgl-container" className="banner">
        <Space3d />
        <BannerDescription />
      </div>
      <Diagonal
        fillClass="diagonal-shape-fill"
        className="diagonal-shape diagonal-shape-bottom"
        dataNegative={false}
        dataFlip={true}
      />
    </section>
  );
}
